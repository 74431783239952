import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as queryString from 'query-string'

const MatchLot = ({ location, pageContext }) => {
  const { lots } = pageContext

  useEffect(() => {
    const { lotID } = queryString.parse(location.search)
    let filtered = null

    if (lotID && lots.length) {
      filtered = lots.filter((lot) => parseInt(lot.id) === parseInt(lotID))

      if (filtered.length && typeof window !== `undefined`) {
        window.location.replace(filtered[0].path)
      } else {
        window.location.replace(`/`)
      }
    }
  }, [lots, location])

  return <div>Matching...</div>
}

MatchLot.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export default MatchLot
